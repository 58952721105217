"use client";

import React from "react";

import type { RootState } from "../GlobalRedux/Store";
import { useSelector, useDispatch } from "react-redux";

import { CHANGE_THEME } from "../GlobalRedux/features/theme/themeSlice";

import { MdLightMode, MdDarkMode } from "react-icons/md";

interface ThemeButtonProp {
  size?: number;
}

const ThemeButton: React.FC<ThemeButtonProp> = ({ size }) => {
  const dispatch = useDispatch();

  const theme = useSelector((state: RootState) => state.theme.themeValue);

  return (
    <>
      <p
        style={{ borderRadius: "50%", padding: "0" }}
        onClick={() => dispatch(CHANGE_THEME())}
      >
        {theme === "dark" ? (
          <MdLightMode size={size} />
        ) : (
          <MdDarkMode size={size} />
        )}
      </p>
    </>
  );
};

export default ThemeButton;
