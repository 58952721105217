"use client";
import React, { FormEvent, useEffect, useState } from "react";
import { useRouter } from "next/navigation";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/app/GlobalRedux/Store";
import { emailListFormData } from "@/app/GlobalRedux/features/emailSubscribe/emailSubscribeInterface";
import { RESET, SubscribeToMailList } from "@/app/GlobalRedux/features/emailSubscribe/emailSubscribeSlice";

import { regex } from "@/app/utils/forms-utils/regex";
import styling from "./Subscribe.module.scss";

const Subscribe = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const { isLoadingEmailSub, isSuccessEmailSub } = useSelector(
    (state: RootState) => state.emailSubscribe
  );

  const validateEmail = (email: string) => {
    return regex.test(email);
  };

  const handleSubscribeToEmailList = async (e: FormEvent) => {
    e.preventDefault();

    // Validate the email
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    // Clear any previous error messages
    setEmailError("");

    // create the data
    const data: emailListFormData = {
      emailAddress: email,
    };

    console.log(data)
    await dispatch(SubscribeToMailList(data) as any);
  };

  
  useEffect(() => {
    if (isSuccessEmailSub && !isLoadingEmailSub) {
      setEmailError("");
      router.push("/");
    }
    dispatch(RESET());
  }, [ isSuccessEmailSub, dispatch, router, isLoadingEmailSub]);

  return (
    <form className={styling.subscribe} onSubmit={handleSubscribeToEmailList}>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      {emailError && <p className={styling.error}>{emailError}</p>}
      <button type="submit" disabled={isLoadingEmailSub}>
        {isLoadingEmailSub ? "Subscribing..." : "Subscribe"}
      </button>
    </form>
  );
};

export default Subscribe;
